@media only screen and (max-width: 600px) {
  body {
    width: 100vw;
  }
  .App-header {
    max-width: 100vw;
  }
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .App {
    display: flex;
    width: 100vw;
  }
}

.App {
  font-family: "Roboto", sans-serif;
  text-align: center;
  background-color: #a60202;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  height: max-content;
  padding: 0 0 6rem 0;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    width: 6rem;
    animation: App-logo-spin infinite 1s linear;
  }
}

.App-header {
  font-size: calc(2rem + 2vmin);
  background-color: #a60202;
  color: #ffc003;
  text-transform: uppercase;
  font-weight: 900;
}

.App-header h1 {
  font-size: 2rem;
}
.App-header h2 {
  font-size: 1rem;
  padding: 0.5rem;
}

@media only screen and (min-width: 600px) {
  .App-header h1 {
    margin-bottom: 0.875rem;
  }

  .App-header h2 {
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #1c1c19;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  width: 80vw;
  padding: 1rem;
  font-size: 1rem;
  font-weight: normal;
  color: white;
  text-transform: none;
  justify-content: space-between;
}
@media only screen and (min-width: 600px) {
  .form-container {
    display: flex;
    flex-direction: row;
  }
}

.form-container button {
  padding: 0.875rem;
  text-transform: uppercase;
  border: none;
  border-radius: 0.375em;
  background-color: #ffc003;
  font-weight: 800;
  justify-self: flex-end;
  cursor: pointer;
}

.App-form {
  display: inline-flex;
  align-items: center;
  max-width: max-content;
}

.App-form select,
.App-form input {
  border: none;
  border-radius: 0.375em;
  box-shadow: none;
  background-color: #ffffff;
  padding: 0.875em 1em 0.75em;
}

.App-form label {
  margin: 0.5rem;
  text-transform: uppercase;
  font-weight: 800;
}

.App-form button {
  margin: 1rem;
}

.App-warning {
  width: 80vw;
  padding: 1rem;
  color: #000;
  font-size: 1rem;
  font-weight: normal;
}

.App-content {
  width: 80vw;
  background-color: #f3f3f1;
  background: repeating-linear-gradient(
    -45deg,
    #fff,
    #fff 8px,
    #f3f3f1 8px,
    #f3f3f1 16px
  );
  padding: 1rem;
  color: #000;
  text-transform: none;
  font-size: 1rem;
  font-weight: normal;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  border-bottom: 5px solid #ffc003;
}

.App-link {
  color: #f1dd3e;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#paragraphs {
  max-width: max-content;
  width: 3rem;
}

.App-alert {
  display: flex;
  width: 80vw;
  padding: 0.25rem 1rem;
  margin-bottom: 0.25rem;
  background-color: #ffd966;
  align-items: center;
}

footer {
  padding: 0.25rem;
}

footer {
  position: relative;
  bottom: 0;
  color: #f3f3f1;
  font-size: 0.8rem;
}

footer a {
  color: #ffd966;
}
@media only screen and (in-width: 600px) {
  footer {
    padding: 1rem;
  }
}
